import React,{useState} from 'react';
import Title from './components/Pictures/Title';
import ImageGrid from './components/Pictures/ImageGrid';
import Modal from './components/Pictures/Modal';
import UploadForm from './components/Pictures/UploadForm';
import ProgressBar from './components/Pictures/ProgressBar';



function App() {

  const [selectedImg, setSelectedImg] = useState(null);

  return (
    <div className="App">
      <Title/>
      {/*<UploadForm/>*/}
      <ImageGrid setSelectedImg = {setSelectedImg} />
      {selectedImg && <Modal selectedImg = {selectedImg} setSelectedImg = {setSelectedImg} /> } 
  </div>
  );
}

export default App;
