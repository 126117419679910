// Import the functions you need from the SDKs you need
import firebase from 'firebase/app';
import 'firebase/firestore';
import "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCUZYvmI1NLsDpXASjRwFyc3pTu3xAGUYE",
  authDomain: "chriztopherton-c3337.firebaseapp.com",
  projectId: "chriztopherton-c3337",
  storageBucket: "chriztopherton-c3337.appspot.com",
  messagingSenderId: "234253570173",
  appId: "1:234253570173:web:862c3202b3a2115949e114",
  measurementId: "G-KJ9CEFLD44"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const projectStorage = firebase.storage(); 
const projectFirestore = firebase.firestore();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;


export {projectStorage, projectFirestore, timestamp};