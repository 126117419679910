import React from 'react';

const Title = () => {
  return (
    <>
    <div className = "container">
    <div className="intro">
      <div className = "intro-image">
        <img src="selfie.jpeg" alt="Selfie"></img>
      </div>
      <div className = "intro-layout" >
      <div className="title">
      <h3 className ="profile-user-name"> @christobruhton </h3>
    </div>
      <p> 👋 Hello! Cameras I'm currently using 📸 : <b>Fujifilm X-T100</b> & <b>Canon EOS M50</b></p>
    </div>
    </div>
    </div>
    </>
  )
}

export default Title;