import React from 'react';
import { motion } from 'framer-motion';


const Modal = ({ setSelectedImg, selectedImg }) => {

  const handleClick = (e) => {
    if (e.target.classList.contains('backdrop')) {
      setSelectedImg(null);
    }
  }


  return (


    <div className="backdrop" onClick={handleClick}
      initial={{ opacity: 0 }}
    >
      <img src={selectedImg} alt="enlarged pic" />
    </div>
    
  )
}

export default Modal; 